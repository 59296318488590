<template>
  <div>
    <div class="mb-2">
      <b-form-file
        v-model="file"
        :state="
          Boolean(file) &&
          file &&
          ((file.size <= 2097152 && restrict_file_weight) ||
            !restrict_file_weight)
        "
        aria-describedby="input-file-feedback"
        placeholder="Seleccione un archivo o arrástrelo aquí..."
        browse-text="Buscar"
      >
        <!-- <template slot="file-name" slot-scope="{ names }">
          <b-badge variant="primary">{{ names[0] }}</b-badge>
          <b-badge v-if="names.length > 1" variant="primary" class="ml-1">
            + {{ names.length - 1 }} More files
          </b-badge>
        </template> -->
      </b-form-file>
      <b-form-invalid-feedback id="input-file-feedback">
        <span v-if="file && file.size > 2097152 && restrict_file_weight">
          El archivo no puede superar 2MB de tamaño.
        </span></b-form-invalid-feedback
      >
      <div v-if="url" class="file-div">
        <!-- <b-img :src="url" fluid alt="file"></b-img> -->
      </div>
      <div class="buttons-div">
        <b-button
          v-if="url && !upload_file"
          :disabled="file && file.size > 2097152 && restrict_file_weight"
          @click="save"
          class="mt-2"
          >Guardar</b-button
        >
        <b-button v-else-if="url && upload_file" :disabled="true" class="mt-2">
          Guardando...
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as names from "@/store/names";
import { toast } from "@/utils/utils";

export default {
  name: "FileSelector",
  props: {
    // Attachments: {
    //   type: [Array, Number],
    //   required: true,
    // },
    post_file: {
      type: Boolean,
      default: true,
    },
    restrict_file_weight: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      file: null,
      url: null,
      data: null,
      upload_file: false,
    };
  },
  computed: {
    ...mapGetters({
      files: names.FILES,
    }),
  },
  methods: {
    save() {
      if (
        this.file &&
        ((this.file.size <= 2097152 && this.restrict_file_weight) ||
          !this.restrict_file_weight)
      ) {
        const formData = new FormData();
        formData.append("file", this.file);
        if (this.post_file) {
          this.upload_file = true;
          this.$store.dispatch(names.POST_FILE, formData).then((response) => {
            this.$emit("created", response);
            toast("Archivo guardado.");
            this.upload_file = false;
          });
        } else this.$emit("file", formData);
      }
    },
  },
  watch: {
    file() {
      this.url = URL.createObjectURL(this.file);
    },
  },
};
</script>

<style scoped>
.file-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  align-self: center;
  justify-items: center;
  width: 100%;
}
/* img {
  max-width: 200px;
  max-height: 200px;
} */
.buttons-div {
  text-align: right;
}
</style>